// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-tsx": () => import("./../../../src/pages/articles.tsx" /* webpackChunkName: "component---src-pages-articles-tsx" */),
  "component---src-pages-democracy-perception-index-report-2021-tsx": () => import("./../../../src/pages/democracy-perception-index-report-2021.tsx" /* webpackChunkName: "component---src-pages-democracy-perception-index-report-2021-tsx" */),
  "component---src-pages-democracy-perception-index-tsx": () => import("./../../../src/pages/democracy-perception-index.tsx" /* webpackChunkName: "component---src-pages-democracy-perception-index-tsx" */),
  "component---src-pages-engineering-tsx": () => import("./../../../src/pages/engineering.tsx" /* webpackChunkName: "component---src-pages-engineering-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-glossary-tsx": () => import("./../../../src/pages/glossary.tsx" /* webpackChunkName: "component---src-pages-glossary-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-knowledge-center-tsx": () => import("./../../../src/pages/knowledge-center.tsx" /* webpackChunkName: "component---src-pages-knowledge-center-tsx" */),
  "component---src-templates-article-template-tsx": () => import("./../../../src/templates/articleTemplate.tsx" /* webpackChunkName: "component---src-templates-article-template-tsx" */),
  "component---src-templates-author-template-tsx": () => import("./../../../src/templates/authorTemplate.tsx" /* webpackChunkName: "component---src-templates-author-template-tsx" */),
  "component---src-templates-engineering-post-tsx": () => import("./../../../src/templates/engineeringPost.tsx" /* webpackChunkName: "component---src-templates-engineering-post-tsx" */),
  "component---src-templates-faq-post-tsx": () => import("./../../../src/templates/faqPost.tsx" /* webpackChunkName: "component---src-templates-faq-post-tsx" */),
  "component---src-templates-glossary-post-tsx": () => import("./../../../src/templates/glossaryPost.tsx" /* webpackChunkName: "component---src-templates-glossary-post-tsx" */),
  "component---src-templates-knowledge-post-tsx": () => import("./../../../src/templates/knowledgePost.tsx" /* webpackChunkName: "component---src-templates-knowledge-post-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */)
}

